import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'

const InputsLegalPerson = ({ userInfo, setUserInfo }) => {
	return (
		<GridItem xs={12}>
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Razón Social *'
						id='investor-businessName'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.businessName,
							onChange: (e) => {
								setUserInfo({ ...userInfo, businessName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='CIF *'
						id='investor-cif'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.nif,
							onChange: (e) => {
								setUserInfo({ ...userInfo, nif: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Email  *'}
						id='investor-email'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.email,
							onChange: (e) => {
								setUserInfo({ ...userInfo, email: e.target.value })
							},
							type: 'email',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Teléfono *'}
						id='investor-phone'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.phone,
							onChange: (e) => {
								setUserInfo({ ...userInfo, phone: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} md={4}>
					<CustomInput
						labelText={'Provincia *'}
						id='investor-province'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.province,
							onChange: (e) => {
								setUserInfo({ ...userInfo, province: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={4}>
					<CustomInput
						labelText={'Localidad *'}
						id='investor-location'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.location,
							onChange: (e) => {
								setUserInfo({ ...userInfo, location: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} md={4}>
					<CustomInput
						labelText={'Codigo postal *'}
						id='investor-postalCode'
						error={
							(userInfo.postalCode && userInfo.postalCode.length < 5) ||
							(userInfo.postalCode && userInfo.postalCode.length > 5)
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.postalCode,
							onChange: (e) => {
								setUserInfo({ ...userInfo, postalCode: e.target.value })
							},
							type: 'number',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText={'Dirección *'}
						id='investor-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.address,
							onChange: (e) => {
								setUserInfo({ ...userInfo, address: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText={'IBAN '}
						id='investor-iban'
						helperText='Formato ES9999999999999999999999'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.bankAccountNumber,
							onChange: (e) => {
								setUserInfo({ ...userInfo, bankAccountNumber: e.target.value })
							},
							type: 'text',
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Nombre Representante*'}
						id='representative-name'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.representativeName,
							onChange: (e) => {
								setUserInfo({ ...userInfo, representativeName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Apellidos Representante*'}
						id='investor-identification'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.representativeLastName,
							onChange: (e) => {
								setUserInfo({ ...userInfo, representativeLastName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'DNI/NIE Representante*'}
						id='investor-identification'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.representativeNif,
							onChange: (e) => {
								setUserInfo({ ...userInfo, representativeNif: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Dirección Representante *'
						id='investor-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.addressOfTheRepresentative,
							onChange: (e) => {
								setUserInfo({ ...userInfo, addressOfTheRepresentative: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
			</GridContainer>
		</GridItem>
	)
}

export default InputsLegalPerson
