import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CoPresent } from '@mui/icons-material'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { getPrescribersByProfiles } from 'shared/mappers/prescriber-mappers'
import { getPrescriberProfileListById } from 'local_redux/actions/prescriberActions'
import styles from './styles/profilePrescriberScreenStyles'

const useStyles = makeStyles(styles)

const ProfilePrescriberScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [data, setData] = useState([])

	const { loadingPrescriberProfileListById, successPrescriberProfileListById, prescribers } = useSelector(
		(state) => state.prescriberProfileList,
	)

	useEffect(() => {
		dispatch(getPrescriberProfileListById())
	}, [dispatch])

	useEffect(() => {
		if (successPrescriberProfileListById) {
			const prescriber = getPrescribersByProfiles(prescribers)
			setData(prescriber)
		}
	}, [successPrescriberProfileListById])

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					{loadingPrescriberProfileListById ? (
						<GridItem xs={12} className={classes.loader}>
							<Loader />
						</GridItem>
					) : prescribers && prescribers?.prescribers !== 0 ? (
						<Card>
							<CardHeader color='primary' icon>
								<CardIcon color='primary'>
									<CoPresent />
								</CardIcon>
								<p className={classes.counterData}>
									Perfiles Prescritos: <strong>{prescribers?.prescribers}</strong>
								</p>
							</CardHeader>
							<CardBody>
								{prescribers?.investments?.length ? (
									<ReactTable
										columns={[
											{
												Header: 'Perfil',
												accessor: 'investorProfile',
												size: 'big',
											},
											{
												Header: 'Proyecto',
												accessor: 'projectAddress',
												size: 'small',
											},
											{
												Header: 'Importe Invertido',
												accessor: 'amount',
												size: 'small',
											},
											{
												Header: 'Fecha de Inversión',
												accessor: 'incomeRecivedDate',
												size: 'small',
											},
											{
												Header: 'Fecha de Fin',
												accessor: 'endDate',
												size: 'small',
											},
											{
												Header: 'Comisión',
												accessor: 'bonus',
												size: 'small',
											},
											{
												Header: 'Estado',
												accessor: 'status',
												size: 'small',
											},
											{
												Header: 'Tir',
												accessor: 'tir',
												size: 'small',
											},
											{
												Header: 'Actions',
												accessor: 'actions',
												size: 'small',
											},
										]}
										data={data}
									/>
								) : (
									<CustomWarningMessage text='No se encontraron inversiones de ningúno de tus perfiles prescriptores' />
								)}
							</CardBody>
						</Card>
					) : (
						<CustomWarningMessage text='No se encontraron perfiles prescriptores' />
					)}
				</GridItem>
			</GridContainer>
		</>
	)
}

export default ProfilePrescriberScreen
