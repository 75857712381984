const excelColums = () => {
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'investorProfile',
		},
		{
			header: 'Fecha Registro',
			accessor: 'createdAt',
			type: 'date',
		},
		{
			header: 'Fecha Inicio',
			accessor: 'startDate',
			type: 'date',
		},
		{
			header: 'Fecha Fin',
			accessor: 'finishDate',
			type: 'date',
		},
		{
			header: 'Importe',
			accessor: 'amount',
			type: 'currency',
		},
		{
			header: 'Interés',
			accessor: 'typesOfInterestRates',
		},
		{
			header: 'Estado',
			accessor: 'status',
		},
		{
			header: 'Gestionado por',
			accessor: 'user',
		},
	]
}

export default excelColums
