import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import Button from 'components/CustomButtons/Button'
import DownloadInvestmentContractsModal from 'components/DownloadInvestmentContractsModal/DownloadInvestmentContractsModal'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import NotesModal from 'components/CustomModals/NotesModal/NotesModal'
import DeleteIncomeDateModal from './components/DeleteIncomeDateModal'
import IncomeConfirmationModal from './components/IncomeConfirmationModal'
import CancelPDLModal from './components/CancelPDLModal'
import DownloadStatusModal from './components/DownloadStatusModal'
import { pdlListColumns } from './helpers/pdl-list-columns'
import { pdlListMapper, excelPdlListMapper } from './mappers/pdl-list-mapper'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { getProjectLiquidityList } from 'local_redux/actions/projectLiquidityActions'
import { updateProjectLiquidity } from 'local_redux/actions/projectLiquidityActions'
import { registerLog } from 'local_redux/actions/logActions'
import { PROFILE_GET_NOTES_RESET } from 'local_redux/constants/userConstants'
import { getContractsInfoToDownloadPDL } from 'local_redux/actions/projectLiquidityActions'
import {
	PROJECT_LIQUIDITY_LIST_RESET,
	GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET,
} from 'local_redux/constants/projectLiquidityConstants'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/pdlListScrenStyles'
const useStyles = makeStyles(styles)

const PdlListScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const tableId = 2

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext) //context of table

	const [projects, setProjects] = useState([])
	const [data, setData] = useState([])
	const [showIncomeRecivedDateModal, setShowIncomeRecivedDateModal] = useState(false)
	const [showDeleteIncomeRecivedDateModal, setShowDeleteIncomeRecivedDateModal] = useState(false)
	const [selectedData, setSelectedData] = useState(null)
	const [openCancelPDLModal, setOpenCancelPDLModal] = useState(false)
	const [openDownloadContractModal, setOpenDownloadContractModal] = useState(false)
	const [openDownloadStatusModal, setOpenDownloadStatusModal] = useState(false)
	const [pdlInfo, setPdlInfo] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [urlsToDownload, setUrlsToDownload] = useState([])
	const [zipFileName, setZipFileName] = useState('')

	const {
		loadingProjectLiquidityList,
		successProjectLiquidityList,
		projectsLiquiditiesList,
		errorProjectLiquidityList,
	} = useSelector(({ projectLiquidityList }) => projectLiquidityList)
	const {
		loadingContractsInfoToDownloadPDL,
		successContractsInfoToDownloadPDL,
		contractsInfoToDownloadPDL,
		errorContractsInfoToDownloadPDL,
	} = useSelector(({ contractsInfoToDownloadPDL }) => contractsInfoToDownloadPDL)
	const { successAdded } = useSelector((state) => state[`investNotes`])
	const { successProjectLiquidityUpdate, projectLiquidityUpdated } = useSelector(
		(state) => state.projectLiquidityUpdate,
	)
	const { userInfo } = useSelector(({ userLogin }) => userLogin)

	useEffect(() => {
		dispatch(getProjectLiquidityList())
		return () => {
			dispatch({ type: PROJECT_LIQUIDITY_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (successProjectLiquidityList) {
			setProjects(projectsLiquiditiesList)
		}
	}, [successProjectLiquidityList])
	useEffect(() => {
		if (successAdded) {
			setData(
				data.map((item) => {
					if (item.id === showModal?._id) {
						item.hasComments = 'Tiene'
					}
					return item
				}),
			)
			return () => dispatch({ type: PROFILE_GET_NOTES_RESET })
		}
	}, [successAdded])
	useEffect(() => {
		if (projects?.length > 0) {
			const filterProjects = projects.filter((item) => item.status !== 'deleted')
			setData(pdlListMapper(filterProjects))
		} else {
			setData([])
		}
	}, [projects])
	useEffect(() => {
		if (successProjectLiquidityUpdate) {
			const dataUpdate = projects?.map((project) => {
				if (project._id === projectLiquidityUpdated._id) {
					project.incomeRecivedDate = projectLiquidityUpdated.incomeRecivedDate || null
				}
				return project
			})
			setProjects(dataUpdate)
		}
	}, [successProjectLiquidityUpdate])

	const handleIncomeRecivedDate = (data) => {
		setShowIncomeRecivedDateModal(true)
		setSelectedData(data)
	}
	const handleDeleteIncomeRecivedDate = (data) => {
		setShowDeleteIncomeRecivedDateModal(true)
		setSelectedData(data)
	}
	const updateIncomeRecivedDate = (id, date, userEmail) => {
		const data = {
			field: 'incomeRecivedDate',
			value: date,
			userEmail,
		}
		dispatch(updateProjectLiquidity(id, data))
	}
	const handleCloseIncomeModal = () => {
		setShowIncomeRecivedDateModal(false)
		setSelectedData(null)
	}
	const handleCloseIncomeDeleteModal = () => {
		setShowDeleteIncomeRecivedDateModal(false)
		setSelectedData(null)
	}
	const deleteIncomeRecivedDate = (id, reason) => {
		dispatch(updateProjectLiquidity(id, { field: 'incomeRecivedDate', reason }))
	}
	const handleDownload = ({ urls, zipFileName, ids }) => {
		const logInfo = {
			type: {
				type: 'download',
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			dataDownloadInfo: {
				model: 'projectliquidities',
				field: 'contractUrl',
				docIds: ids,
			},
		}
		setOpenDownloadStatusModal(true)
		setOpenDownloadContractModal(false)
		setUrlsToDownload(urls)
		setZipFileName(zipFileName)
		dispatch(registerLog(logInfo))
	}
	const handleCloseStatus = () => {
		setOpenDownloadStatusModal(false)
		dispatch({ type: LOG_REGISTER_RESET })
	}
	const handleDeployNotesModal = (user) => {
		setShowModal(user)
	}

	return (
		<Card>
			<CardHeader color='primary' icon>
				<CardIcon color='primary'>
					<Assignment />
				</CardIcon>
				<h4 className={classes.cardIconTitle}>Listado de PDL</h4>
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					{Boolean(getFilteredDataRows({ tableId })?.length) && (
						<ExcelDefaultExport
							excelName='Listado de PDL'
							tableColumns={getFilteredDataColumns({ tableId })}
							data={excelPdlListMapper({ filteredRows: getFilteredDataRows({ tableId }), userInfo: userInfo })}
						/>
					)}
					{(userInfo?.isSuper || userInfo?.isInvestorRelations) && (
						<Button style={{ margin: '5px 0px 15px 12px' }} onClick={() => setOpenDownloadContractModal(true)}>
							Descargar contratos
						</Button>
					)}
				</div>
			</CardHeader>
			<CardBody>
				<ReactVirtualizedTable
					tableId={tableId}
					data={data}
					columns={pdlListColumns({
						handleIncomeRecivedDate,
						handleDeleteIncomeRecivedDate,
						userInfo,
						setOpenCancelPDLModal,
						setPdlInfo,
						classes,
						handleDeployNotesModal,
					})}
					loading={Boolean(loadingProjectLiquidityList)}
					success={Boolean(successProjectLiquidityList)}
					error={errorProjectLiquidityList}
				/>
			</CardBody>
			{showIncomeRecivedDateModal && (
				<IncomeConfirmationModal
					handleCloseIncomeUpdateModal={handleCloseIncomeModal}
					incomeUpdateModal={showIncomeRecivedDateModal}
					showIncomeUpdateInfo={selectedData}
					updateIncomeRecivedDate={updateIncomeRecivedDate}
				/>
			)}
			{showDeleteIncomeRecivedDateModal && (
				<DeleteIncomeDateModal
					deleteInvestmentDateAdminModal={showDeleteIncomeRecivedDateModal}
					handleCloseInvestmentDateAdminModal={handleCloseIncomeDeleteModal}
					showDeleteInvestmentDateInfo={selectedData}
					deleteIncomeRecivedDate={deleteIncomeRecivedDate}
				/>
			)}
			{openCancelPDLModal && (
				<CancelPDLModal
					openModal={openCancelPDLModal}
					setOpenModal={setOpenCancelPDLModal}
					pDLInfo={pdlInfo}
					setProjects={setProjects}
				/>
			)}
			{openDownloadContractModal && (
				<DownloadInvestmentContractsModal
					openModal={openDownloadContractModal}
					closeModal={() => setOpenDownloadContractModal(false)}
					handleDownload={handleDownload}
					loadingContractsInfoToDownload={loadingContractsInfoToDownloadPDL}
					successContractsInfoToDownload={successContractsInfoToDownloadPDL}
					contractsInfoToDownload={contractsInfoToDownloadPDL}
					errorContractsInfoToDownload={errorContractsInfoToDownloadPDL}
					getContractsInfoToDownload={getContractsInfoToDownloadPDL}
					GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET={GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET}
				/>
			)}
			{openDownloadStatusModal && (
				<DownloadStatusModal
					openModal={openDownloadStatusModal}
					closeModal={handleCloseStatus}
					urls={urlsToDownload}
					setUrlsToDownload={setUrlsToDownload}
					zipFileName={zipFileName}
				/>
			)}
			{showModal && (
				<NotesModal
					type='invest'
					open={showModal}
					handleCloseModal={() => setShowModal(false)}
					modalTitle={'Comentarios'}
				/>
			)}
		</Card>
	)
}

export default PdlListScreen
