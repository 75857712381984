import { matchPersonType } from 'shared/matchData/matchData'

export const investorProfileAdminListMapper = (investorProfiles) => {
	return investorProfiles?.map((item) => {
		return {
			id: item._id,
			personType: matchPersonType(item.personType),
			businessName: item.businessName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			owner: `${item.owner.name} ${item.owner.lastName}`,
			prescriber: item?.prescriber?.length > 0 ? item.prescriber[0].businessName : 'Sin Prescriptor',
			dni: item.nif,
			fiscalNationality: item.fiscalNationality,
			bankAccountNumber: item.bankAccountNumber,
			postalCode: item.postalCode,
			address: item.address,
			item: item,
		}
	})
}
