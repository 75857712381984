import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import DetailInvestmentAdminModal from 'components/DetailInvestmentAdminModal/DetailInvestmentAdminModal'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TimeFilterCard from 'components/TimeFilterCard/TimeFilterCard'
import ExcelExport from 'components/ExcelExport/ExcelExport'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import DownloadInvestmentContractsModal from 'components/DownloadInvestmentContractsModal/DownloadInvestmentContractsModal'
import DownloadStatusModal from './components/DownloadStatusModal'
import GenerateRCForLiquitedInvestmentModal from './components/GenerateRCForLiquitedInvestmentModal'
import InvestmentCommentsModal from './components/InvestmentCommentsModal'
import LiquidatedInvestmentsListResume from './components/LiquidatedInvestmentsListResume'
import ReinvestmentModalv2 from './components/ReinvestmentModalv2'
import ReInvestmentPDLModal from './components/ReInvestmentPDLModal'
import UpdateInvestmentStatusModal from './components/UpdateInvestmentStatusModal'
import LIQUIDATEDINVESTMENT_COLUMNS from './helpers/liquidated-investment-columns'
import excelColums from './helpers/excel-data-columns'
import liquidatedInvestmentMapper from './mappers/liquidated-investment-mapper'
import excelData from './mappers/excel-data-mappers'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { registerLog } from 'local_redux/actions/logActions'
import {
	getContractsInfoToDownload,
	getLiquidatedInvestmentsList,
	updateInvestmentStatus,
} from 'local_redux/actions/investmentActions'
import {
	LIQUIDATED_INVESTMENT_LIST_RESET,
	GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET,
} from 'local_redux/constants/investmentConstants'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/liquidatedInvestmentScreenStyles'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const useStyles = makeStyles(styles)

const LiquidatedInvestmentScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const tableId = 10
	const initialStateDates = {
		startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
		endDate: new Date(),
	}

	const [filterDate, setFilterDate] = useState(initialStateDates)
	const [alert, setAlert] = useState(null)
	const [data, setData] = useState([])
	const [investments, setInvestments] = useState([])
	const [detailInvestmentAdminModal, setDetailInvestmentAdminModal] = useState(false)
	const [investmentId, setInvestmentId] = useState(false)
	const [openDownloadContractModal, setOpenDownloadContractModal] = useState(false)
	const [openDownloadStatusModal, setOpenDownloadStatusModal] = useState(false)
	const [openInvestmentCommmentModal, setOpenInvestmentCommmentModal] = useState(false)
	const [openReinvestmentModal, setOpenReinvestmentModal] = useState(false)
	const [openReinvestmentPDLModal, setOpenReinvestmentPDLModal] = useState(false)
	const [openUpdateInvestmentStatus, setOpenUpdateInvestmentStatus] = useState(false)
	const [showDetailInvestmentAdminInfo, setShowDetailInvestmentAdminInfo] = useState({})
	const [showReinvestAdminInfo, setShowReinvestAdminInfo] = useState({})
	const [urlsToDownload, setUrlsToDownload] = useState([])
	const [zipFileName, setZipFileName] = useState('')
	const [showGenerateRCModal, setShowGenerateRCModal] = useState(false)
	const [idForRetentionCertificate, setIdForRetentionCertificate] = useState(null)

	const { getFilteredDataRows } = useContext(FilterReactVirtualizedTableContext) //context of table

	const { userInfo } = useSelector((state) => state.userLogin)
	const {
		liquidatedInvestmentsListData,
		loadingLiquidatedInvestmentsList,
		successLiquidatedInvestmentsList,
		errorLiquidatedInvestmentsList,
	} = useSelector((state) => state.liquidatedInvestmentsList)
	const {
		loadingContractsInfoToDownload,
		successContractsInfoToDownload,
		contractsInfoToDownload,
		errorContractsInfoToDownload,
	} = useSelector(({ contractsInfoToDownload }) => contractsInfoToDownload)
	const { reinvestmentRegisterSuccess } = useSelector((state) => state.reInvestmentRegister)
	const { successPdlRegisterByInvestment } = useSelector((state) => state.pdlRegisterByInvestment)
	const { successUpdateInvestmentStatus, updateInvestmentStatusData } = useSelector(
		(state) => state.updateInvestmentStatus,
	)

	useEffect(() => {
		if (successUpdateInvestmentStatus) {
			setInvestments((prev) =>
				prev.map((item) =>
					item._id === updateInvestmentStatusData._id
						? { ...item, liquidation: updateInvestmentStatusData.liquidation }
						: item,
				),
			)
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px', textAlign: 'center' }}
					title='¡Hecho!'
					onConfirm={() => setAlert(null)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Inversión actualizada Correctamente!
				</SweetAlert>,
			)
		}
	}, [successUpdateInvestmentStatus])
	useEffect(() => {
		dispatch(getLiquidatedInvestmentsList(initialStateDates.startDate, initialStateDates.endDate))
	}, [reinvestmentRegisterSuccess, successPdlRegisterByInvestment])
	useEffect(() => {
		if (successLiquidatedInvestmentsList) {
			setInvestments(liquidatedInvestmentsListData)
		}
	}, [successLiquidatedInvestmentsList])
	useEffect(() => {
		setData(liquidatedInvestmentMapper(investments))
	}, [investments])
	useEffect(() => {
		return () => dispatch({ type: LIQUIDATED_INVESTMENT_LIST_RESET })
	}, [dispatch])

	const handleCloseDeclineAdminModal = () => {
		closeModal(setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)
	}
	const handleCloseReinvestAdminModal = () => {
		closeModal(setShowReinvestAdminInfo, setOpenReinvestmentModal)
	}
	const handleCloseReinvestPDLModal = () => {
		closeModal(setShowReinvestAdminInfo, setOpenReinvestmentPDLModal)
	}
	const handlePayStatus = (investor) => {
		const data = {
			_id: investor._id,
			liquidation: 'pay',
		}
		dispatch(updateInvestmentStatus(data))
	}
	const handleDownload = ({ urls, zipFileName, ids }) => {
		const logInfo = {
			type: {
				type: 'download',
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			dataDownloadInfo: {
				model: 'investments',
				field: 'contractUrl',
				docIds: ids,
			},
		}
		setOpenDownloadStatusModal(true)
		setOpenDownloadContractModal(false)
		setUrlsToDownload(urls)
		setZipFileName(zipFileName)
		dispatch(registerLog(logInfo))
	}
	const handleCloseStatus = () => {
		setOpenDownloadStatusModal(false)
		dispatch({ type: LOG_REGISTER_RESET })
	}
	const handleCloseGenerateModal = () => {
		setShowGenerateRCModal(false)
		setIdForRetentionCertificate(null)
	}
	const handleFilter = ({ startDate, endDate }) => {
		const start = format(new Date(startDate), 'yyyy-MM-dd', { locale: es })
		const end = format(new Date(endDate), 'yyyy-MM-dd', { locale: es })

		dispatch(getLiquidatedInvestmentsList(start, end))
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<GridContainer>
						<GridItem xs={12} sm={6} style={{ margin: '12px 0 0 0' }}>
							<TimeFilterCard
								filterDate={filterDate}
								setFilterDate={setFilterDate}
								handleFilter={handleFilter}
								title='Inversiones entre'
							/>
						</GridItem>
					</GridContainer>

					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Inversiones Liquidadas</h4>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={12} className={classes.downloadButton}>
										{(userInfo?.isSuper || userInfo?.isInvestorRelations) && (
											<Button onClick={() => setOpenDownloadContractModal(true)}>Descargar contratos</Button>
										)}
									</GridItem>
									<GridItem xs={12}>
										{data.length > 0 && (
											<ExcelExport
												excelName='Inversiones Liquidadas'
												data={excelData(getFilteredDataRows({ tableId }))}
												columns={excelColums()}
											/>
										)}
									</GridItem>
								</GridContainer>
							</GridItem>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								tableId={tableId}
								columns={LIQUIDATEDINVESTMENT_COLUMNS({
									userInfo,
									openModal,
									setShowDetailInvestmentAdminInfo,
									setInvestmentId,
									setDetailInvestmentAdminModal,
									setOpenInvestmentCommmentModal,
									setShowGenerateRCModal,
									setIdForRetentionCertificate,
									setOpenReinvestmentModal,
									setOpenReinvestmentPDLModal,
									setOpenUpdateInvestmentStatus,
									setShowReinvestAdminInfo,
									handlePayStatus,
								})}
								data={data}
								loading={Boolean(loadingLiquidatedInvestmentsList)}
								success={Boolean(successLiquidatedInvestmentsList)}
								error={errorLiquidatedInvestmentsList}
								emptyText={errorLiquidatedInvestmentsList}
							/>
						</CardBody>
					</Card>
				</GridItem>
				{Boolean(getFilteredDataRows({ tableId })?.length) && (
					<GridItem xs={12}>
						<LiquidatedInvestmentsListResume data={getFilteredDataRows({ tableId })} />
					</GridItem>
				)}
			</GridContainer>
			{detailInvestmentAdminModal && (
				<DetailInvestmentAdminModal
					handleCloseDeclineAdminModal={handleCloseDeclineAdminModal}
					detailInvestmentAdminModal={detailInvestmentAdminModal}
					showDetailInvestmentAdminInfo={showDetailInvestmentAdminInfo}
				/>
			)}
			{openUpdateInvestmentStatus && (
				<UpdateInvestmentStatusModal
					setOpenModal={setOpenUpdateInvestmentStatus}
					openModal={openUpdateInvestmentStatus}
					investment={showReinvestAdminInfo}
				/>
			)}
			{openReinvestmentModal && (
				<ReinvestmentModalv2
					handleCloseReinvestAdminModal={handleCloseReinvestAdminModal}
					openReinvestmentModal={openReinvestmentModal}
					setOpenReinvestmentModal={setOpenReinvestmentModal}
					showReinvestAdminInfo={showReinvestAdminInfo}
				/>
			)}
			{openReinvestmentPDLModal && (
				<ReInvestmentPDLModal
					handleCloseReinvestAdminModal={handleCloseReinvestPDLModal}
					openReinvestmentModal={openReinvestmentPDLModal}
					setOpenReinvestmentModal={setOpenReinvestmentPDLModal}
					showReinvestAdminInfo={showReinvestAdminInfo}
				/>
			)}
			{openInvestmentCommmentModal && (
				<InvestmentCommentsModal
					open={openInvestmentCommmentModal}
					close={() => setOpenInvestmentCommmentModal(false)}
					investmentId={investmentId}
				/>
			)}
			{openDownloadContractModal && (
				<DownloadInvestmentContractsModal
					openModal={openDownloadContractModal}
					closeModal={() => setOpenDownloadContractModal(false)}
					handleDownload={handleDownload}
					typeScreen={'liquidated'}
					loadingContractsInfoToDownload={loadingContractsInfoToDownload}
					successContractsInfoToDownload={successContractsInfoToDownload}
					contractsInfoToDownload={contractsInfoToDownload}
					errorContractsInfoToDownload={errorContractsInfoToDownload}
					getContractsInfoToDownload={getContractsInfoToDownload}
					GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET={GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET}
				/>
			)}
			{openDownloadStatusModal && (
				<DownloadStatusModal
					openModal={openDownloadStatusModal}
					closeModal={handleCloseStatus}
					urls={urlsToDownload}
					setUrlsToDownload={setUrlsToDownload}
					zipFileName={zipFileName}
				/>
			)}
			{showGenerateRCModal && (
				<GenerateRCForLiquitedInvestmentModal
					open={showGenerateRCModal}
					close={handleCloseGenerateModal}
					id={idForRetentionCertificate}
				/>
			)}
			{alert}
		</>
	)
}

export default LiquidatedInvestmentScreen
