import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Box, Stepper, Step, StepLabel } from '@mui/material'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import PersonTypeComponent from './components/PersonTypeSelectable'
import PersonTypeForms from './components/PersonTypeForm'
import PersonTypeReview from './components/PersonTypeReview'
import { matchPersonType, matchGender } from 'shared/matchData/matchData'
import { registerProfileInvestor } from 'local_redux/actions/investorProfileActions'
import {
  INVESTOR_PROFILE_REGISTER_RESET,
  INVESTORS_PROFILE_BY_USER_RESET,
} from 'local_redux/constants/investorProfileConstants'
import styles from './styles/investorProfileRegisterStyles'

const useStyles = makeStyles(styles)

const InvestorProfileRegisterScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const steps = ['Tipo de Entidad', 'Complete los Datos', 'Guardar Perfil']

  const initialState = {
    name: '',
    lastName: '',
    identification: '',
    address: '',
    email: '',
    businessName: '',
    personType: '',
    cif: '',
    phone: '',
    gender: '',
    bankAccountNumber: '',
    province: 'Madrid',
    location: 'Madrid',
    postalCode: '28001',
  }

  const [activeStep, setActiveStep] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [state, setState] = useState(initialState)
  const isLegal = state.personType === 'legal'

  const { loadingInvestorProfileRegister, errorInvestorProfileRegister, successInvestorProfileRegister } = useSelector(
    (state) => state.investorProfileRegister
  )

  useEffect(() => {
    const inputsExcludes = ['bankAccountNumber']
    const validations = []

    if (state.personType !== 'legal') {
      inputsExcludes.push('businessName', 'cif')
    } else {
      inputsExcludes.push('gender')
    }

    if (state.personType !== '' && activeStep === 0) {
      setDisabled(false)

      return handleNext()
    } else if (activeStep === 1) {
      for (let key in state) {
        if (!inputsExcludes.includes(key)) {
          if (state[key] === '') {
            validations.push(key)
          }
        }
      }
      if (validations.length) {
        return setDisabled(true)
      } else {
        return setDisabled(false)
      }
    }
  }, [activeStep, state])

  useEffect(() => {
    let timeOut = ''
    if (successInvestorProfileRegister) {
      timeOut = setTimeout(() => {
        dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
        dispatch({ type: INVESTOR_PROFILE_REGISTER_RESET })
        setState(initialState)
        setActiveStep(0)
      }, 1000)
    }
    return () => clearTimeout(timeOut)
  }, [successInvestorProfileRegister])

  useEffect(() => {
    if (errorInvestorProfileRegister) {
      setTimeout(() => {
        setActiveStep(1)
        dispatch({ type: INVESTOR_PROFILE_REGISTER_RESET })
      }, 2000)
    }
  }, [errorInvestorProfileRegister])
  useEffect(() => {
    return () => dispatch({ type: INVESTOR_PROFILE_REGISTER_RESET })
  }, [dispatch])

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }
  const handleBack = () => {
    if (state.personType && activeStep === 1) {
      setState({ ...initialState })
    }
    setActiveStep(activeStep - 1)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (state.postalCode.length < 4 || state.postalCode.length > 5) {
      return
    }
    const data = {
      name: state.name,
      lastName: state.lastName,
      identification: state.identification,
      address: state.address,
      email: state.email,
      gender: matchGender(state.gender),
      businessName: isLegal ? state.businessName : '',
      cif: isLegal ? state.cif : '',
      phone: state.phone,
      bankAccountNumber: state.bankAccountNumber,
      personType: state.personType,
      postalCode: state.postalCode,
      province: state.province,
      location: state.location,
    }
    dispatch(registerProfileInvestor(data))
  }
  const getStepContent = (step, state, setState) => {
    switch (step) {
      case 0:
        return <PersonTypeComponent state={state} setState={setState} />
      case 1:
        return <PersonTypeForms state={state} setState={setState} />
      case 2:
        return <PersonTypeReview state={state} setState={setState} />
      default:
        throw new Error('Unknown step')
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={10} style={{ margin: '20px auto' }}>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep !== steps.length && (
          <form>
            <GridContainer>
              <GridItem xs={12} className={classes.steepsContainer}>
                {getStepContent(activeStep, state, setState)}
              </GridItem>
              {errorInvestorProfileRegister && (
                <GridItem xs={12}>
                  <SnackbarContent message={errorInvestorProfileRegister} color='danger' />
                </GridItem>
              )}
              <GridItem xs={12}>
                <Box className={classes.buttonsContainer}>
                  {activeStep !== 0 && (
                    <Button color='primary' onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Atrás
                    </Button>
                  )}
                  {activeStep !== 0 && activeStep !== steps.length - 1 && (
                    <Button color='primary' disabled={disabled} onClick={handleNext} sx={{ mt: 3, ml: 1 }}>
                      {'Siguiente'}
                    </Button>
                  )}
                  {activeStep === steps.length - 1 && (
                    <Button
                      color={successInvestorProfileRegister ? 'success' : 'primary'}
                      disabled={disabled}
                      onClick={handleSubmit}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {loadingInvestorProfileRegister
                        ? `Creando...`
                        : successInvestorProfileRegister
                        ? 'Creado Correctamente'
                        : `Guardar Perfil ${matchPersonType(state.personType)}`}
                    </Button>
                  )}
                </Box>
              </GridItem>
            </GridContainer>
          </form>
        )}
      </GridItem>
    </GridContainer>
  )
}

export default InvestorProfileRegisterScreen
