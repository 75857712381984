const excelData = (FixedRateLoansListData) => {
	return FixedRateLoansListData?.map((item) => {
		return {
			id: item._id,
			typesOfInterestRates: `${item.typesOfInterestRates} %`,
			investorProfile: item?.businessName,
			registerDate: item.registerDate,
			startDate: item.startDate && new Date(item.startDate),
			finishDate: item.finishDate && new Date(item.finishDate),
			amount: item.amount,
			status: item.status,
			user: item.user ? item.user.name : 'Unknown User',
			createdAt: item.createdAt && new Date(item.createdAt),
		}
	})
}

export default excelData
