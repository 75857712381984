const styles = {
  counterData: {
    color: 'black',
    textAlign: 'end',
    margin: '15px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
}

export default styles
