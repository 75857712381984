import { Tooltip } from '@material-ui/core'
import { Visibility, Delete } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'

export const pdlListColumns = ({
	handleIncomeRecivedDate,
	handleDeleteIncomeRecivedDate,
	userInfo,
	setOpenCancelPDLModal,
	setPdlInfo,
	classes,
	handleDeployNotesModal,
}) => {
	const isAuthorizedUser = userInfo?.isSuper || userInfo?.isContabilidad || userInfo?.isAdmin
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'inversor',
			nullValue: 'Sin nombre',
			cell: ({ row, cell }) => {
				return (
					<span
						onClick={() => window.open(`/admin/investor-list-profiles?profileId=${row?.investorProfileId}`)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{cell}
					</span>
				)
			},
		},
		{
			header: 'Proyecto',
			accessor: 'projectAddress',
		},
		{
			header: 'Comentarios añadidos',
			accessor: 'hasComments',
			size: 'sm',
		},
		{
			header: 'Fecha',
			accessor: 'registerDate',
			type: 'date',
		},
		{
			header: 'Importe',
			accessor: 'import',
			type: 'currency',
		},
		{
			header: 'Contrato',
			accessor: 'contractUrl',
			cell: ({ row }) => {
				if (row?.contractUrl) {
					return (
						<div>
							Firmado
							<Tooltip id='tooltip-top' title='Ver Contrato' placement='bottom'>
								<Button
									justIcon
									round
									simple
									onClick={() => window.open(getUrlToOpenFile(row?.contractUrl, userInfo))}
									color='success'
									className='view'
								>
									<Visibility />
								</Button>
							</Tooltip>
						</div>
					)
				} else {
					return <div>Pendiente de Firma</div>
				}
			},
		},
		{
			header: 'Ingreso Recibido',
			accessor: 'incomeRecivedDate',
			type: 'date',
			dateFormat: 'dd/MM/yyyy',
			cell: ({ row, cell }) => {
				if (!row?.incomeRecivedDate) {
					return !isAuthorizedUser ? (
						<div
							style={{
								color: 'rgb(70, 70, 195)',
							}}
						>
							Pendiente de confirmar
						</div>
					) : (
						<div
							style={{
								color: 'rgb(70, 70, 195)',
								cursor: 'pointer',
							}}
							onClick={() => {
								isAuthorizedUser && handleIncomeRecivedDate(row.data)
							}}
						>
							Confirmar ingreso
						</div>
					)
				} else {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								gap: '10px',
							}}
						>
							{cell}
							{isAuthorizedUser && (
								<Tooltip title='Eliminar Ingreso Recibido' placement='bottom'>
									<Button
										justIcon
										round
										simple
										onClick={() => handleDeleteIncomeRecivedDate(row?.data)}
										color='danger'
										className='delete-date'
									>
										<Delete />
									</Button>
								</Tooltip>
							)}
						</div>
					)
				}
			},
		},
		{
			header: 'Gestionado por',
			accessor: 'user',
			nullValue: 'Sin nombre',
		},
		{
			header: 'Intereses',
			accessor: 'typeOfInterest',
		},
		{
			header: 'Estado',
			accessor: 'status',
			cell: ({ row }) => (
				<Tooltip
					title={
						!row?.liquidated
							? 'Activo'
							: `Liquidado el ${format(new Date(row?.liquitationDate), 'dd/MM/yyyy', { locale: es })}`
					}
				>
					<div>{!row?.liquidated ? 'Activo' : 'Liquidado'}</div>
				</Tooltip>
			),
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			cell: ({ row }) => (
				<OptionsMenuTable
					options={[
						<div className={classes.dropDownItem} onClick={() => handleDeployNotesModal({ ...row, _id: row.id })}>
							<p>Comentarios</p>
						</div>,
					].concat(
						row?.status !== 'deleted' && (
							<Tooltip title='Anular PDL' placement='bottom'>
								<div
									className={classes.option}
									onClick={() => {
										setOpenCancelPDLModal(true)
										setPdlInfo(row?.data)
									}}
								>
									<Delete />
									<p>Anular</p>
								</div>
							</Tooltip>
						),
					)}
				/>
			),
		},
	]
}
