import { formatCurrency } from 'shared/helpers/currency-helpers'

const excelColums = () => {
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'investorProfile',
		},
		{
			header: 'Email',
			accessor: 'email',
		},
		{
			header: 'Dirección de Proyecto',
			accessor: 'projectAddress',
		},
		{
			header: 'Importe',
			accessor: 'amount',
		},
		{
			header: 'Retención',
			accessor: 'retention',
		},
		{
			header: 'Beneficio',
			accessor: 'benefit',
		},
		{
			header: 'Importe de retención',
			accessor: 'withHoldingAmount',
		},
		{
			header: 'A Pagar',
			accessor: 'toPay',
		},
		{
			header: 'Confirmación Ingreso',
			accessor: 'incomeRecivedDate',
		},
		{
			header: 'Fecha Inicio Proyecto',
			accessor: 'startDateUpdated',
		},
		{
			header: 'Fecha Liquidación',
			accessor: 'dateLiquidation',
		},
		{
			header: 'TIR Inversor',
			accessor: 'investorTIR',
		},
		{
			header: 'Fondos',
			accessor: 'liquidation',
		},
	]
}

export default excelColums
