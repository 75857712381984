import { differenceInDays, format } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { FileCopy, VideoCameraBack, Article, PictureAsPdf, Description, Videocam, Replay } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import { getLiquidationStatus, liquidationMatch } from '../helper/functions'
import { calculateToTIR } from '../../ProjectLiquidateScreen/functions'
import { matchProjectStatus } from 'shared/matchData/matchData'
import { changeIncomeReciveDate } from 'shared/helpers/functions-helpers'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { generateTokenCode } from 'shared/helpers/generateTokenCode'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { formatCurrency } from 'shared/helpers/currency-helpers'

export const investmentFinishedMapper = (
	investmentsFinished,
	videoShowHandler,
	navigate,
	userInfo,
	openModal,
	setShowGenerateRCModal,
	setIdForRetentionCertificate,
) => {
	return investmentsFinished.map((investment) => {
		const {
			deedOfPurchase,
			simpleNotePurchasedProperty,
			acquisitionExpenses,
			billsOfExpenses,
			deedOfSale,
			simpleNotePriorToPurchase,
		} = investment.project

		const isDocumentsPresent = new Set([
			deedOfPurchase,
			simpleNotePurchasedProperty,
			acquisitionExpenses,
			billsOfExpenses,
			deedOfSale,
			simpleNotePriorToPurchase,
		])

		const liquidationStatus = getLiquidationStatus(investment)

		// This patchDate is because some of liquidated investment having field -> liquidation = 'reinvest' was paid anyways
		// So they dont need to have de reinvest button cause the investor already have the money paid
		// For all cases before 2023-07-25
		const patchDateHardcode = new Date('2023-07-25')
		const liquidationDate = investment?.finished[0]?.createdAt && new Date(investment.finished[0]?.createdAt)
		return {
			profileId: investment.investorProfile,
			id: investment._id,
			profileInvestor: investment?.investor?.businessName,
			incomeRecivedDate: investment?.incomeRecivedDate
				? format(new Date(investment.incomeRecivedDate), 'dd-MM-yyyy')
				: 'Sin Fecha',
			toPay: investment?.toPay || 0,
			amount: investment?.amount || formatCurrency(investment?.amountPdl) || 'No establecido',
			project: investment?.project?.projectAddress,
			investmentType: investment?.investmentType ? 'PDL' : 'Coinversión',
			status: matchProjectStatus(investment?.project?.status),
			benefit:
				investment?.benefit ||
				(investment?.liquidationFields?.benefit && formatCurrency(investment?.liquidationFields?.benefit)) ||
				(!investment?.liquidated && investment.investmentType === 'PDL' && 'No ha sido liquidado') ||
				'0,00 €',
			liquidation:
				(liquidationStatus === liquidationMatch.reinvest || liquidationStatus === liquidationMatch['benefits-only']) &&
				liquidationDate.getTime() >= patchDateHardcode.getTime() ? (
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
						<span style={{ width: '60%' }}>{liquidationStatus}</span>
						<Tooltip id='tooltip-top' title='Reinvertir fondos' placement='bottom'>
							<Button justIcon round simple onClick={() => openModal(investment)} color='success' className='edit'>
								<Replay />
							</Button>
						</Tooltip>
					</div>
				) : (
					liquidationStatus
				),
			startDateUpdated: format(new Date(investment.project.startDateUpdated), 'dd-MM-yyyy'),
			liquidatedDate: investment?.finished[0]?.createdAt
				? format(new Date(investment?.finished[0]?.createdAt), 'dd-MM-yyyy')
				: 'No liquidado',
			TIR:
				investment?.incomeRecivedDate && investment?.depositDate && investment?.benefit && investment?.amount
					? `${calculateToTIR(
							changeIncomeReciveDate(investment.incomeRecivedDate, investment.project.startDateUpdated),
							investment.depositDate,
							replaceStringToNum(investment.amount) + replaceStringToNum(investment.benefit),
							investment.amount,
					  )}%`
					: investment?.investmentType === 'PDL'
					? 'Sin TIR'
					: '0%',
			date:
				investment.investmentType === 'PDL'
					? investment?.project?.finishDateUpdated && investment?.project?.startDateUpdated
						? ` ${differenceInDays(
								new Date(investment?.project?.finishDateUpdated),
								new Date(investment?.project?.startDateUpdated),
						  )}`
						: 'Sin Fecha'
					: investment.depositDate && investment.incomeRecivedDate
					? ` ${differenceInDays(
							new Date(investment.depositDate),
							new Date(changeIncomeReciveDate(investment.incomeRecivedDate, investment.project.startDateUpdated)),
					  )}`
					: 'Sin Fecha',
			actions: (
				<div className='actions-right'>
					{investment?.project?.simpleNotePriorToPurchase && (
						<Tooltip id='tooltip-top' title='Descargar Nota Simple' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() =>
									window.open(
										`${process.env.REACT_APP_API}/${
											investment?.project?.simpleNotePriorToPurchase
										}?code=${generateTokenCode(userInfo.token)}`,
									)
								}
								color='success'
								className='download-Document'
							>
								<Article />
							</Button>
						</Tooltip>
					)}
					{investment?.endDocuments[0]?.documentPath && userInfo.token && (
						<Tooltip id='tooltip-top' title='Descargar Dossier Fin de Proyecto' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => window.open(getUrlToOpenFile(investment?.endDocuments[0]?.documentPath, userInfo))}
								color='success'
								className='download-Document'
							>
								<PictureAsPdf />
							</Button>
						</Tooltip>
					)}
					{investment?.investmentType !== 'PDL' && investment.finished?.length > 0 && (
						<Tooltip id='tooltip-top' title='Descargar Certificado de Retenciones' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => {
									setIdForRetentionCertificate(investment._id)
									setShowGenerateRCModal(true)
								}}
								color='success'
								className='download-Document'
							>
								<FileCopy />
							</Button>
						</Tooltip>
					)}
					{investment.project?.images?.length > 0 && (
						<Tooltip id='tooltip-top' title='Ver Evolución del Proyecto' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => videoShowHandler(investment.project, 'images')}
								color='success'
								className='download-Document'
							>
								<VideoCameraBack />
							</Button>
						</Tooltip>
					)}
					{investment.project?.coverVideo && (
						<Tooltip id='tooltip-top' title='Ver video' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => videoShowHandler(investment.project, 'coverVideo')}
								color='success'
								className='download-Document'
							>
								<Videocam />
							</Button>
						</Tooltip>
					)}
					{Array.from(isDocumentsPresent).filter((item) => item)?.length > 0 && (
						<Tooltip id='tooltip-top' title='Documentos' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => navigate(`/admin/projects?projectId=${investment.project._id}`)}
								color='success'
								className='documents'
							>
								<Description />
							</Button>
						</Tooltip>
					)}
				</div>
			),
		}
	})
}
